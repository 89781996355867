import React, { useState } from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import CommunityTemplate from "../../component/CommunityTemplate";

const CommunityDetail = (props: any) => {
  const [siteTitle, setSiteTitle] = useState("");
  const [siteDesc, setSiteDesc] = useState("");
  const pageData = {
    id: "mira-mesa-homes-for-sale",
    titleTag: "Mira Mesa Homes for Sale | San Diego Real Estate | Selby",
    descTag:
      "Find homes for sale in Mira Mesa, a San Diego suburb with friendly neighbors and lots to do. Contact The Selby Team to learn more about this great neighborhood.",
  };
  return (
    <LayoutWrapper
      title={pageData.titleTag}
      desc={pageData.descTag}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <CommunityTemplate
        id={pageData.id}
        setSiteTitle={setSiteTitle}
        setSiteDesc={setSiteDesc}
        listingId="40653"
      />
    </LayoutWrapper>
  );
};

export default CommunityDetail;
